import React, { useState } from 'react';
import './Design.css';

const Design = () => {
  const images = [
    '/D (1).jpg',
    '/D (2).jpg',
    '/D (3).jpg',
    '/D (4).jpg',
    '/D (5).jpg',
    '/D (6).jpg',
    '/D (7).jpg',
    '/D (8).jpg',
    '/D (9).jpg',
    '/D (10).jpg',
    '/D (11).jpg',
    '/D (12).jpg',
    '/D (13).jpg',
    '/D (14).jpg',
    '/D (15).jpg',
    '/D (16).jpg',
    '/D (17).jpg',
    '/D (18).jpg',
    '/D (19).jpg',
    '/D (20).jpg',
    '/D (21).jpg',
    '/D (21).jpg',
    '/D (23).jpg',
    '/D (24).jpg',
    '/D (25).jpg',
    '/D (26).jpg',
    '/D (27).jpg',
    '/D (28).jpg',
    '/D (29).jpg',
    '/D (30).jpg',
  ];

  const [popupImage, setPopupImage] = useState(null);

  const openPopup = (image) => {
    setPopupImage(image);
  };

  const closePopup = () => {
    setPopupImage(null);
  };

  return (
    <div className="design-container">
      <div className="design-header">
        <p className="design-subtitle">Design</p>
        <h1 className="design-title">Our Custom Designs</h1>
        <p className="design-hashtag">See the creativity we bring to life</p>
      </div>

      <div className="design-grid">
        {images.map((image, index) => (
          <div key={index} className="design-item" onClick={() => openPopup(image)}>
            <img src={image} alt={`Design ${index + 1}`} />
          </div>
        ))}
      </div>

      {/* Popup */}
      {popupImage && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content">
            <img src={popupImage} alt="Popup" />
            <button className="close-button" onClick={closePopup}>
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Design;
